<!-- eslint-disable max-len -->
<template>
  <div class="londo-input">
    <div class="londo-input__group">
      <label :for="inputId" class="londo-input__group__label input-label">{{
        label
      }}</label>
      <select
        class="londo-input__group__input input input-input select-input"
        :id="inputId"
        :ref="inputId"
        :name="inputName"
        :value="modelValue"
        @change="onChange"
        @mousedown="onMouseDown"
        @focus="onFocus"
      >
        <slot name="options" />
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "LondoSelect",
  props: {
    inputName: {
      required: true,
      type: String,
    },
    inputId: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    modelValue: {
      required: true,
    },
    error: {
      required: false,
      type: String,
    },
  },
  methods: {
    onChange(event) {
      this.$emit("update", event);
    },
    onMouseDown(event) {
      event.stopPropagation();
      this.$emit("mousedown", event);
    },
    onFocus(event) {
      this.$emit("focus", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-input {
  min-width: auto;
}
.londo-input__group {
  &__label {
    padding-left: 2px;
  }
}
</style>
